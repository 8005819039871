import Layout from "components/layout";
import React from "react";
import Sydney from "components/locationIndexPages/sydney/index";
import SEO from "components/seo";

export default function index() {
  const title = "Sydney Translation Services Agency - Tomedes";
  const description =
    "High-Quality translation services in Sydney by our NAATI-certified translators. Translate in over 120 languages and 950+ language pairings. 1-Year Guarantee | Fast Delivery | 24/7 Availability";
  const keywords = "Translation Services in Sydney";
  return (
    <Layout>
      <SEO
        title={title}
        description={description}
        keywords={keywords}
        slug="/locations/sydney-translation-services"
      />
      <Sydney />
    </Layout>
  );
}
